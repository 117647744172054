// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~vue-sidebar-menu/dist/vue-sidebar-menu.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~vue-promise-btn/dist/vue-promise-btn.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600);

#main-app-container {
    padding-left: 250px;
    -webkit-transition: .3s ease;
    transition: .3s ease
}

#main-app-container.collapsed {
    padding-left: 50px
}

#main-app-container.onmobile {
    padding-left: 50px
}

.sidebar-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: .5;
    z-index: 900
}

#demo {
    padding: 50px
}

.container {
    max-width: 900px
}


$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
    width: 184px;
    height: 42px;
    background-color: $google-blue;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);

    .google-icon-wrapper {
        position: absolute;
        margin-top: 1px;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: $white;
    }

    .google-icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 11px;
        width: 18px;
        height: 18px;
    }

    .btn-text {
        float: right;
        margin: 11px 11px 0 0;
        color: $white;
        font-size: 14px;
        letter-spacing: 0.2px;
        font-family: "Roboto";
    }

    &:hover {
        box-shadow: 0 0 6px $google-blue;
    }

    &:active {
        background: $button-active-blue;
    }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);

.video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
}

.video-responsive iframe, .video-responsive object, .video-responsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.fieldset{
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
    box-shadow:  0px 0px 0px 0px #000;
}
